@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();
$tb-tools-primary: mat.define-palette(mat.$gray-palette, 700);
$tb-tools-accent: mat.define-palette(mat.$red-palette);
$tb-tools-warn: mat.define-palette(mat.$deep-orange-palette);
$tb-tools-theme: mat.define-light-theme((
  color: (
    primary: $tb-tools-primary,
    accent: $tb-tools-accent,
    warn: $tb-tools-warn,
  )
));
@include mat.all-component-themes($tb-tools-theme);

$base-unit: 4px;
$font-unit: $base-unit*2;
$grid-unit: $base-unit*3;
$container-size: 1280px;
$grid-sm-breakpoint: 960px;
$grid-xs-breakpoint: 480px;

:root {
  // PRIMARY
  // --primary-red: 236, 34, 39;             // #EC2227
  --new-blue: 19, 32, 60;
  --primary-red: 209, 34, 45;             // #
  --primary-blue: 23, 86, 181;            // #1756B5
  --primary-white: 255, 255, 250;         // #FFFFFA
  --primary-black: 0, 1, 3;               // #000103
  --primary-grey: 59, 52, 52;             // #807979
  // SECONDARY
  --secondary-red: 194, 22, 10;           // #C2160A
  --secondary-green: 0, 160, 63;          // #00a03f
  --secondary-blue: 17, 157, 164;         // #119DA4
  --secondary-grey: 197, 191, 191;        // #C5BFBF
  --secondary-light-grey: 240, 240, 240;  // #F0F0F0
  // SIMPLE
  --simple-white: 255, 255, 255;         // #FFFFFF
  --simple-black: 0, 0, 0;               // #000000
}
