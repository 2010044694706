@import "theme.scss";
@import "layout.scss";
@import "~swiper/swiper-bundle.css";

@mixin styling($base-unit, $grid-unit, $font-unit, $container-size) {
  html, body {
    height: 100%;
  }
  body {
    margin: 0;
    font-size: $grid-unit;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: rgb(var(--primary-black));
    background: rgb(var(--primary-white));
  }
  b, .bold {
    font-weight: 500;
  }

  h1, h2, h3, h4, h5, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5 {
    font-family: 'Montserrat', sans-serif;
    color: rgb(var(--primary-dark));
    margin-top: 0.5em;
    &.no-margin {
      margin-bottom: 0!important;
    }
  }
  h1, .heading-1 {
    font-weight: 700;
    font-size: $font-unit * 4.25;
    line-height: $font-unit * 5;
    margin-bottom: $grid-unit * 2;
    .mat-icon {
      height: $font-unit * 5;
    }
  }
  h2, .heading-2 {
    font-weight: 700;
    font-size: $font-unit * 3.5;
    line-height: $font-unit * 4.25;
    margin-bottom: $grid-unit;
    .mat-icon {
      height: $font-unit * 4.25;
    }
  }
  h3, .heading-3 {
    font-weight: 400;
    font-size: $font-unit * 2.5;
    line-height: $font-unit * 3;
    margin-bottom: $grid-unit;
    &.bold {
      font-weight: 700;
    }
    .mat-icon {
      height: $font-unit * 3;
    }
  }
  h4, .heading-4 {
    font-weight: 500;
    font-size: $font-unit * 2.25;
    line-height: $font-unit * 3;
    color: rgb(var(--secondary-grey));
    margin-bottom: $grid-unit;
    .mat-icon {
      height: $font-unit * 3;
    }
  }
  h5, .heading-5 {
    font-weight: 600;
    font-size: $font-unit * 2;
    line-height: $font-unit * 2.75;
    color: rgb(var(--new-blue));
    margin-bottom: $grid-unit*0.5;
    .mat-icon {
      height: $font-unit * 2.75;
    }
  }
  b, .bold {
    font-weight: 700;
  }
  .medium {
    font-weight: 500;
  }
  small, .tiny-text {
    font-size: $font-unit * 1.5;
    line-height: $font-unit * 1.75;
  }

  .max-width {
    width: 100% !important;
  }
  .align-right {
    text-align: right;
  }

  .top-spacer {
    margin-top: $grid-unit !important;
  }
  .bottom-spacer {
    margin-bottom: $grid-unit !important;
  }
  .right-spacer {
    margin-right: $grid-unit !important;
  }
  .left-spacer {
    margin-left: $grid-unit !important;
  }

  .desktop {
    display: initial;
  }
  .mobile {
    display: none;
  }

  .flex {
    display: flex;
    flex-direction: row;
    &:not(.flex-column) > *:not(:last-child) {
      margin-right: $base-unit*3;
    }
    &.flex-column {
      flex-direction: column;
      & > *:not(:last-child) {
        margin-bottom: $base-unit*3;
      }
    }
    &.space-between {
      justify-content: space-between;
    }
  }

  .logo {
    cursor: pointer;
  }
  .color-primary {
    color: rgb(var(--primary-red)) !important;
  }
  .color-white {
    color: white !important;
  }
  .swiper {
    margin: $grid-unit*3 0;
    .swiper-slide {
      text-align: center;
      img {
        max-height: $grid-unit*60;
        width: auto;
        border-radius: $grid-unit*0.5;
        margin: $grid-unit;
        box-shadow: 0 $base-unit $grid-unit 0 rgba(var(--simple-black), 0.25);
      }
      .image-description {
        font-weight: 500;
        font-size: $font-unit * 2.25;
        line-height: $font-unit * 3;
        color: rgb(var(--new-blue));
      }
    }
    .swiper-button-next, .swiper-button-prev {
      background: rgb(var(--new-blue));
      color: rgb(var(--simple-white));
      width: var(--swiper-navigation-size);
      padding: $grid-unit;
      border-radius: $grid-unit*0.5;
      box-shadow: 0 $base-unit $base-unit 0 rgba(var(--simple-black), 0.25);
    }
  }

  .floating-header {
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;
    z-index: 3;
    display: flex;
    & > div {
      margin: auto;
      background: rgb(var(--primary-red));
      color: rgb(var(--simple-white));
      padding: $grid-unit ($grid-unit*2);
      border-radius: $grid-unit*0.5;
    }
  }

  button.mat-button-base {
    &.mat-flat-button {
      &.mat-primary {
        color: rgb(var(--simple-white));
        background-color: rgb(var(--primary-red));
      }
      &.mat-button-disabled {
        opacity: 0.5;
      }
    }
  }

  .page-content {
    .content-header {
      text-shadow: $base-unit $base-unit ($grid-unit*0.5) rgba(var(--primary-black), 1);
      min-height: $grid-unit*24;
      .title {
        color: rgb(var(--primary-white));
        margin-top: $grid-unit*4;
      }
      .description {
        color: rgb(var(--primary-white));
        font-size: $grid-unit*2;
      }
      .content-background {
        position: absolute;
        z-index: -1;
        top: $grid-unit*13.5;
        left: 0;
        width: 100%;
        height: $grid-unit*30;
        background-image: url("assets/images/banner.jpg");
        background-size: cover;
        background-attachment: fixed;
      }
    }
    .content-with-sidebar {
      display: flex;
      .sidebar {
        flex-grow: 1;
        margin-top: -$grid-unit*2;
        min-width: 25%;
        max-width: 25%;
        z-index: 2;
        mat-card {
          padding: 0;
          box-shadow: 0 $base-unit $grid-unit 0 rgba(var(--simple-black), 0.4);
          border-radius: $grid-unit*0.5;
          overflow: hidden;
          .sidebar-menu {
            &.mat-expansion-panel {
              padding: 0;
              box-shadow: none;
              border-radius: 0;
              .mat-expansion-panel-header {
                background: rgb(var(--secondary-light-grey));
                font-weight: 500;
                font-size: $grid-unit + $base-unit;
                .mat-expansion-panel-header-title {
                  color: rgb(var(--primary-red));
                }
                .mat-expansion-panel-header-description {
                  text-align: right;
                  justify-content: flex-end;
                  margin-right: 0;
                  color: rgb(var(--primary-red));
                  fa-icon {
                    transition: 300ms;
                  }
                }
              }
              .mat-expansion-panel-body {
                .subtitle {
                  padding: $grid-unit 0 $base-unit;
                  font-weight: 500;
                  color: rgb(var(--new-blue));
                  margin: 0;
                }
                .detail {
                  padding: $base-unit 0 $base-unit $grid-unit;
                }
              }
              &.mat-expansion-panel-spacing {
                margin: 0;
              }
              &.mat-expanded {
                .mat-expansion-panel-header {
                  .mat-expansion-panel-header-description {
                    fa-icon {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
          }
        }
        &.no-inset {
          margin-top: 0;
        }
      }
      .content {
        flex-grow: 2;
        font-size: $grid-unit + $base-unit;
        margin-bottom: $grid-unit*4;
        margin-top: $grid-unit*2;
      }
      & > *:not(:last-child) {
        margin-right: $grid-unit*2;
      }
    }
  }

  .mat-form-field {
    &.no-hint {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
    }
  }

  .layout-content {
    height:100vh;
    display:flex;
    flex-direction:column;
    .page-header {
      background: white;
      .primary-row {
        text-align: center;
        max-width: calc(#{$container-size} - #{$grid-unit*3});
        width: calc(100vw - #{$grid-unit*3});
        margin: auto;
        padding: ($grid-unit + $base-unit) 0;
        .logo {
          max-height: $grid-unit*8;
        }
      }
      .navigation-row {
        background: rgb(var(--primary-red));
        .navigation-list {
          max-width: calc(#{$container-size} - #{$grid-unit*3});
          width: calc(100vw - #{$grid-unit*3});
          margin: auto;
          .navigation-item {
            border-radius: 0;
            color: white;
            padding: ($base-unit*0.5) ($grid-unit*1.5);
            &.active {
              background: white;
              color: rgb(var(--primary-red));
            }
          }
        }
      }
    }
    .page-content {
      max-width: calc(#{$container-size} - #{$grid-unit*3});
      width: calc(100vw - #{$grid-unit*3});
      margin: ($grid-unit*2) auto;
    }
    .page-footer {
      background: rgb(var(--new-blue));
      color: rgb(var(--primary-white));
      height: 100%;
      .footer-content {
        max-width: calc(#{$container-size} - #{$grid-unit*3});
        width: calc(100vw - #{$grid-unit*3});
        margin: ($grid-unit*2) auto;
        .footer-right {
          .logo {
            max-height: $grid-unit*4;
            max-width: $grid-unit*10;
          }
        }
      }
    }
  }

  app-break {
    width: 100%;
    height: $grid-unit*2;
    display: flex;
    justify-content: space-between;
    margin: -$grid-unit 0;
    position: absolute;
    left: 0;
    z-index: 1;
  }
}
@media screen and (max-width: $grid-sm-breakpoint) {
  $base-unit: 3px;
  @include styling($base-unit: $base-unit, $grid-unit: $base-unit*3, $font-unit: $base-unit*2, $container-size: 960px);

  .swiper {
    .swiper-button-next, .swiper-button-prev {
      width: calc(var(--swiper-navigation-size) * 0.75);
      height: calc(var(--swiper-navigation-size) * 0.75);
      &:after {
        font-size: calc(var(--swiper-navigation-size) * 0.75);
      }
    }
  }

}
@media screen and (min-width: $grid-sm-breakpoint + 1) {
  @include styling($base-unit: $base-unit, $grid-unit: $grid-unit, $font-unit: $font-unit, $container-size: $container-size);
}
@media screen and (max-width: $grid-xs-breakpoint) {
  $base-unit: 3px;
  $font-unit: $base-unit*2;
  $grid-unit: $base-unit*3;
  $container-size: 480px;

  .content-with-sidebar {
    display: flex;
    flex-direction: column;
    .sidebar {
      margin-top: -$grid-unit*3 !important;
      min-width: 100% !important;
      max-width: 100% !important;
      &.no-inset {
        margin-top: 0 !important;
      }
    }
    & > *:not(:last-child) {
      margin-right: 0 !important;
    }
    &.flex-reverse-mobile {
      flex-direction: column-reverse;
    }
  }

  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
    &.mobile-inline {
      display: inline-block !important;
    }
  }

  .flex {
    &.flex-column-mobile {
      flex-direction: column;
    }
  }

  .swiper {
    .swiper-slide {
      img {
        width: 100%;
      }
    }
    .swiper-button-next, .swiper-button-prev {
      width: calc(var(--swiper-navigation-size) * 0.5);
      height: calc(var(--swiper-navigation-size) * 0.5);
      &:after {
        font-size: calc(var(--swiper-navigation-size) * 0.5);
      }
    }
  }

}
